import React from 'react';
import styles from './Footer.module.css';

const Footer = props => (
    <div className={ styles.Footer }>
        <div className={ styles.ContactContainer }>
            <h3>Ginz GmbH</h3>
            <p>Kreuzstr. 116</p>
            <p>79540 Lörrach</p>
            <p>Tel.: +49 (0) 7621 / 2902</p>
            <p>Fax.: +49 (0) 7621 / 2918</p>
            <p>E-Mail: info@ginz-gmbh.com</p>
        </div>

        <div className={ styles.ImpressumPrivacyContainer }>
            <a href='/impressum' className= { styles.Impressum }><p className= { styles.Impressum }>Impressum</p></a>
            <a href='/privacy'className= { styles.Privacy }><p className= { styles.Privacy }>Datenschutzerklärung</p></a>
        </div>
    </div>
);

export default Footer;