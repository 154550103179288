import React, { Component } from 'react';
import styles from './Titlepage.module.css';
import titlepageText from '../../data/titlepageText.js';

class Jumbotron extends Component {
    render() {

        console.log(titlepageText)

        const header_label = titlepageText.header_label[this.props.language]
        const sub_header_label = titlepageText.sub_header_label[this.props.language]

        return(
            <span>
                <div className={styles.title_container}>
                    <div className={styles.jumbotron}/>
                    <div className={styles.header_container}>
                        <h1 className={styles.header_label}> { header_label} </h1>
                        <h2 className={styles.sub_header_label}> { sub_header_label } </h2>
                        <img src="https://i.ibb.co/y8zfzNt/made-in-germany.png" alt="made-in-germany" className={styles.stamp}/>
                    </div>
                </div>
            </span>
        );
    }
}

export default Jumbotron;