const aboutUs = {
    header_label: {
        ger: 'Über uns',
        eng: 'About us'
    },
    sub_header_label: {
        ger: 'Hier arbeiten ...',
        eng: 'Here work ...'
    },
    employeeData: {
        Employee1: {
            name: {
                ger: 'Robert Ginz',
                eng: 'Robert Ginz'
            },
            role: {
                ger: 'Geschäftsführer',
                eng: 'CEO'
            },
            academicTitle: {
                ger: ['Dipl. Ing. (FH) Maschinenbau', 'Schweißfachingenieur'],
                eng: ['Dipl. Ing. (FH) Mechanical Engineering', 'Welding engineer EWE / IWE']
            },
            imgPath: {
                ger: 'https://i.ibb.co/JqKLdTZ/robert-ginz.jpg',
                eng: 'https://i.ibb.co/JqKLdTZ/robert-ginz.jpg'
            },
        },
        Employee2: {
            name: {
                ger: 'Florian Ginz',
                eng: 'Florian Ginz'
            },
            role: {
                ger: null,
                eng: null
            },
            academicTitle: {
                ger: ['Metallbau-Meister Konstruktionstechnik', 'Industriemechaniker', 'Schweißfachmann'],
                eng: ['Metallbau-Meister Konstruktionstechnik', 'Industriemechaniker', 'Schweißfachmann']
            },
            imgPath: {
                ger: 'https://i.ibb.co/0yJcY5f/florian-ginz.jpg',
                eng: 'https://i.ibb.co/0yJcY5f/florian-ginz.jpg'
            },
        },
        Employee3: {
            name: {
                ger: 'Benjamin Ginz',
                eng: 'Benjamin Ginz'
            },
            role: {
                ger: null,
                eng: null
            },
            academicTitle: {
                ger: ['Metallbau-Meister Konstruktionstechnik', 'Mechatroniker', 'Elektrofachkraft', 'QM Beauftragter', 'Schweißfachingenieur'],
                eng: ['Metallbau-Meister Konstruktionstechnik', 'Mechatroniker', 'Elektrofachkraft', 'QM Beauftragter', 'Welding engineer']
            },
            imgPath: {
                ger: 'https://i.ibb.co/TYWf7yN/benjamin-ginz.jpg',
                eng: 'https://i.ibb.co/TYWf7yN/benjamin-ginz.jpg'
            },
        },
    }
}
      
export default aboutUs;