import React, { Component } from 'react';
import './App.css';

import Toolbar from './components/Toolbar/Toolbar.js';
import Spacer from './components/allrounder/Spacer.js';
import Footer from './components/Footer/Footer.js';

import Home from './sites/Home.js';
import Impressum from './sites/Impressum.js';
import Privacy from './sites/Privacy.js';

import { BrowserRouter } from 'react-router-dom';
import { Route } from 'react-router-dom';

class App extends Component {

  state = {
    language: 'ger',
    cookiesAccepted: 0
  }

  setLanguageToGermanHandler = () => (
    this.setState({language: 'ger'})
  )

  setLanguageToEnglishHandler = () => (
    this.setState({language: 'eng'})
  )


  render(){

    const languageButtons = (
      <div className='languageButtonContainer'>
          <button onClick={this.setLanguageToGermanHandler} className='languageButton'>GERMAN</button>
          <button onClick={this.setLanguageToEnglishHandler} className='languageButton'>ENGLISH</button>
      </div>
    );

    return (
      <BrowserRouter>
        <div className="App">

          <div className="toolbarContainer">
            <Toolbar lang={ this.state.language }></Toolbar>
            <Spacer></Spacer>
            { languageButtons }
          </div>

          <div className='contentContainer'>

            <Route 
              path="/" 
              exact 
              render={ (props) => 
                <Home {...props} lang={ this.state.language }/> 
              } 
            />
            <Route path="/impressum" exact component={ Impressum } />
            <Route path="/privacy" exact component={ Privacy } />

          </div>

          <Footer></Footer>

        </div>
      </BrowserRouter>
    );
  }
}

export default App;
