import React, { Component } from 'react';
import styles from './Toolbar.module.css';
import DrawerToggleButton from '../allrounder/DrawerToggleButton.js';
import Backdrop from '../allrounder/Backdrop.js';
import Sidedrawer from '../allrounder/Sidedrawer.js';
import drawerOptions from '../../data/toolbarText.js';
import { ScrollingProvider, useScrollSection } from 'react-scroll-section';


let sidedrawer = null;

class Toolbar extends Component {

    state = {
        sidedrawer: 0
    }

    activateSidedrawer = () => {
        sidedrawer = (
            <div>
                <div className={styles.sidedrawer}>
                    <Sidedrawer drawerOptions={drawerOptions} language={this.props.lang}/>
                </div>
                <div className={styles.backdrop} onClick={this.deactivateSidedrawer}>
                    <Backdrop />
                </div>
            </div>
        );
        this.setState({sidedrawer: 1})
        return sidedrawer;
    }
    
    deactivateSidedrawer = () => {
        sidedrawer = null;
        this.setState({sidedrawer: 0});
        return sidedrawer;
    }

    render() {
        
        return(
            <header className={styles.toolbar}>
                <nav className={styles.navigation}>
                    { sidedrawer }
                    <div className={styles.toolbar_toggle_button} onClick={this.activateSidedrawer}>
                        <DrawerToggleButton />
                    </div>
                    <div className={styles.logo_container}>
                        <div className={styles.logo}><a href="/"><img src="https://i.ibb.co/bNBjfMZ/Ginz-Gmb-H-Logo.png" className={ styles.img } alt="Ginz-Gmb-H-Logo" border="0" /></a></div>
                    </div>
                    <div className={styles.spacer}/>
                    {/*
                    <div className={styles.navigation_items}>
                        <ul>
                            <li><a href="/"> { drawerOptions.upperOptions.Products[this.props.lang] } </a></li>
                            <li><a href="/"> { drawerOptions.upperOptions.About_Us[this.props.lang] } </a></li>
                        </ul>
                    </div>
                    */}
                </nav>
            </header>
        )
    }
}

export default Toolbar;