const productsText = {
    header_label: {
        ger: 'Unsere Produkte',
        eng: 'Our Products'
    },
    sub_header_label: {
        ger: 'Im Folgenden finden Sie unsere beliebtesten Produkte. Bei individuellen Problemstellungen können wir Sonderanfertigungen herstellen. Nehmen Sie mit uns Kontakt auf, wir beraten Sie gerne.',
        eng: 'To address your individual needs, we are always ready to work on any special design you might require. Do not hesitate to reach out to us – we will be happy to partner with you to identify an optimal solution.'
    },
    developedAccordingTo: {
        ger: 'Entwickelt gemäß:',
        eng: 'Developed according to:'
    },
    developedFor: {
        ger: 'Entwickelt für:',
        eng: 'Developed for:'
    },
    price: {
        ger: 'Preis:',
        eng: 'Price:'
    },
    shipmentConditions: {
        ger: 'Preis netto und exklusive Transport und Zollgebühren.',
        eng: 'Price net and excluding transport and customs fees.'
    }
}
      
export default productsText;