import React, { Component } from 'react';
import styles from './Products.module.css';
import Product from './Product.js';
import productsText from '../../data/productsText.js';

class Products extends Component {
    
    render() {

        const productList = Object.keys(this.props.productList)
            .map(productKey => {
                return(
                    <Product key={productKey} language={ this.props.language } productInfo={this.props.productList[productKey]}></Product>
                )
            });

        const header_label = productsText.header_label[this.props.language]

        const sub_header_label = productsText.sub_header_label[this.props.language]

        return(
            <div className={styles.products}>
                <h3 className={ styles.header_label }> { header_label } </h3>
                <p className={ styles.description_label }> { sub_header_label } </p>
                <div className={ styles.productListContainer }>
                    { productList }
                </div>
            </div>
        );
    }
}

export default Products;