import React from 'react';
import styles from './Employee.module.css';

const Employee = props => {

    let qualification = props.employeeInfo.academicTitle[props.language].join(', ');

    return(
        <div className={ styles.Employee }>

            <div className={styles.imgContainer}>
                <img src={ props.employeeInfo.imgPath[props.language] } className={ styles.img }/>
            </div>

            <div className={styles.txtContainer}>
                <h3>{props.employeeInfo.name[props.language]}</h3>
                <p>{props.employeeInfo.role[props.language]}</p>
                <div className={ styles.qualificationContainer[props.language] }>
                    { qualification }
                </div>
            </div>

        </div>
    );
}

export default Employee;