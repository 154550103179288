const titlepageText = {
    header_label: {
        ger: 'Verkauf und Vermietung von Werkzeugen zur Wartung und Instandhaltung von Luftfahrzeugen',
        eng: 'Sale and rental of aircraft maintenance tools'
    },
    sub_header_label: {
        ger: 'Unsere Werkzeuge reduzieren die Downtime und erhöhen Sicherheit für Personal und Gerät. Unsere Produkte werden bereits an den Flughäfen in Basel-Mulhouse, Dubai, Istanbul und einigen weiteren erfolgreich eingesetzt. Gerne helfen wir auch Ihnen!',
        eng: 'Our tools reduce downtime and increase safety for personnel and equipment. Our products are already successfully used at the airports in Basel-Mulhouse, Dubai, Istanbul and several others. We would be happy to help you too!'
    }
}
      
export default titlepageText;