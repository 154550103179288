const drawerOptions = {
    upperOptions: {
        Products: {
            ger: 'Produkte',
            eng: 'Products'
        },
        About_Us: {
            ger: 'Über uns',
            eng: 'About us'
        },
        Contact: {
            ger: 'Kontakt',
            eng: 'Contact'
        }
    },
    lowerOptions: {
        Impressum: {
            ger: 'Impressum',
            eng: 'Impressum',
            urlPath: '/Impressum'
        },
        Data_Privacy: {
            ger: 'Datenschutz',
            eng: 'Privacy',
            urlPath: '/Privacy'
        }
    }
}

export default drawerOptions;