import React from 'react';
import styles from './Sidedrawer.module.css';
import UpperSidedrawerOption from './UpperSidedrawerOption.js';
import LowerSidedrawerOption from './LowerSidedrawerOption.js';
import Logo from './Logo.js';

const Sidedrawer = props => {

    const transformedUpperOptions = Object.keys(props.drawerOptions.upperOptions)
        .map(optionKey => {
            return(
                <UpperSidedrawerOption info = { props.drawerOptions.upperOptions[optionKey][props.language] } />
            )
        });

    const transformedLowerOptions = Object.keys(props.drawerOptions.lowerOptions)
        .map(optionKey => {
            return(
                <LowerSidedrawerOption info = { props.drawerOptions.lowerOptions[optionKey] } language = {props.language} />
            )
        });

    const logo = <Logo />;

    return(
        <div className={ styles.Sidedrawer }>
            <div className={ styles.LogoContainer } >{ logo }</div>
            <div className={ styles.UpperOptionsContainer } >{ transformedUpperOptions }</div>
            <div className={ styles.LowerOptionsContainer } >{ transformedLowerOptions }</div>
        </div>
    );
}

export default Sidedrawer;