import React from 'react';
import styles from './AboutUs.module.css';
import Employee from './Employee.js'
import aboutUsText from '../../data/aboutUsText.js';

const aboutUs = props => {

    const employeeData = aboutUsText.employeeData;
    const header_label = aboutUsText.header_label[props.language];
    const sub_header_label = aboutUsText.sub_header_label[props.language];


    const employees = Object.keys(employeeData)
        .map(employeeKey => {
            return(
                <Employee key={employeeKey} employeeInfo={employeeData[employeeKey]} language={props.language} />
            )
        });

    return(
        <div className={ styles.aboutUs }>
            <h3 className={ styles.headerLabel }> { header_label } </h3>
            <p className={ styles.descriptionLabel }> { sub_header_label } </p>
            <div className={ styles.employeeContainer }>
                { employees }
            </div>
        </div>
    );
}

export default aboutUs;