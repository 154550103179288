const productList = {
    ProductA: {
      title: {
          ger: 'Hebewerkzeug für TFE',
          eng: 'Engine-sling'
      },
      description: {
          ger: 'Hebewerkzeug für Turbine TFE 731 für die Benutzung am betriebseigenen Kran zum sicheren Arbeiten und Transportieren des Triebwerks.',
          eng: 'Lifting tool for TFE 731 turbine used on the company’s own crane for safe handling during transportation of the engine.'
      },
      dinType: {
          ger: 'DIN EN 1090-1 und -2 sowie DIN EN 3834-3',
          eng: 'DIN EN 1090-1 und -2 and DIN EN 3834-3'
      },
      developedFor: 'TFE 731 2/3/5 20/40/60',
      price: 'on request',
      imgPath: 'https://i.ibb.co/YXy96t2/001-engine-sling.png'
    },
    ProductB: {
      title: {
          ger: 'Käfig für Testlauf der Ram Air Turbine',
          eng: 'Ram air turbine - enclosure (RAT-Cage)'
      },
      description: {
          ger: 'Speziell angepasst an die Rundungen des Rumpfes, um die Sicherheit während des Testlaufs zu gewährleisten. Zum Schutz der Außenhaut ist ein Schaumstoffprofil als Randabschluss eingesetzt. Mittels eines Hubwagens lässt sich der Käfig passgenau unter die Turbinenöffnung platzieren. Die vier Räder sind einzeln höhenverstellbare. Empfohlen wird der Einsatz von vier Fixiergewichten.',
          eng: 'Specifically adapted to the curves of the fuselage to ensure safety during test runs. A foam profile is used as an edge seal to protect the outer skin. Via a lifting carriage the cage can be placed precisely under the turbine opening. The four wheels are individually height-adjustable. We recommended the use of four fixing weights.'
      },
      dinType: {
          ger: 'DIN EN 1090-1 und -2 sowie DIN EN 3834-3',
          eng: 'DIN EN 1090-1 und -2 and DIN EN 3834-3'
      },
      developedFor: 'Gulfstream',
      price: 'on request',
      imgPath: 'https://i.ibb.co/drKnpsd/001-rat-cage.png'
    },
    ProductC: {
      title: {
          ger: 'Transportwagen / Arbeitsstation',
          eng: 'Transport Trolley / Work Station'
      },
      description: {
          ger: 'Transportwagen für Jet Triebwerk sowie auch als Arbeits- und Montagestation in der Werkstatt. Das Triebwerk ist um die Querachse 360 Grad drehbar. 3 Adapterstücke ermöglichen einfaches Montieren des Triebwerks im Wagen. Gefederte Räder ermöglichen einen schonenden Transport.',
          eng: 'Transport Trolleys for jet engines, can also be used as a work and assembly station in the workshop. The engine can be rotated 360 degrees around the transverse axis. Three adapter pieces allow easy mounting of the engine in the trolley. Spring-mounted wheels enable gentle transport.'
      },
      dinType: {
          ger: 'DIN EN 1090-1 und -2 sowie DIN EN 3834-3',
          eng: 'DIN EN 1090-1 und -2 and DIN EN 3834-3'
      },
      developedFor: 'HTF 7000',
      price: 'on request',
      imgPath: 'https://i.ibb.co/SncL5LK/HTF7000-Rollwagen-2.jpg'
    },
    ProductD: {
      title: {
          ger: 'Wagen für Transport und Lagerung des Fahrwerks',
          eng: 'Gear Support'
      },
      description: {
          ger: 'Wagen für Transport und Lagerung des Fahrwerks, inkl. verschiedener Aufnahmen. Zusätzlich mit hydraulischer Hubvorrichtung zur Demontage und Montage des Fahrwerks.',
          eng: 'Carriage for transport and storage of the chassis, including various shots. You can optionally order a hydraulic lifting device for assembly and disassembly of the chassis.'
      },
      dinType: {
          ger: 'DIN EN 1090-1 und -2 sowie DIN EN 3834-3',
          eng: 'DIN EN 1090-1 und -2 and DIN EN 3834-3'
      },
      developedFor: 'Falcon',
      price: 'on request -',
      imgPath: 'https://i.ibb.co/WgVQG6g/Gear-support-Hydraulik.jpg'
    },
    ProductE: {
      title: {
          ger: 'Stütz - Ausrüstung',
          eng: 'Shoring Equipment'
      },
      description: {
          ger: 'Speziell angepasst an die Rundungen von Rumpf (vorne und hinten), 4 Tragflächenstützen. Zum Schutz der Oberfläche des Flugzeugs wird Holz und Gummi als Auflager eingesetzt.',
          eng: 'Specifically adapted to the curves of the fuselage-stations (front and rear). Four wing pads protect the aircraft’s surface, wood and rubber are used as supports.'
      },
      dinType: {
          ger: 'DIN EN 1090-1 und -2 sowie DIN EN 3834-3',
          eng: 'DIN EN 1090-1 und -2 and DIN EN 3834-3'
      },
      developedFor: 'Gulfstream IV/V',
      price: 'on request',
      imgPath: 'https://i.ibb.co/bFRFrPr/Bild-01.jpg'
    },
    ProductF: {
      title: {
          ger: 'Hebewerkzeug für Höhenleitwerk',
          eng: 'Horizontal Stabilizer'
      },
      description: {
          ger: 'Das Werkzeug dient zur Demontage sowie zur Montage des Höhenleitwerks, das Werkzeug ist umrüstbar für die Modelle G/2/3/4/5.',
          eng: 'The tool is used for the disassembly as well as for the assembly of the tailplane, the tool is convertible for the models G/2/3/4/5.'
      },
      dinType: {
          ger: 'DIN EN 1090-1 und -2 sowie DIN EN 3834-3',
          eng: 'DIN EN 1090-1 und -2 and DIN EN 3834-3'
      },
      developedFor: 'Gulfstream',
      price: 'on request',
      imgPath: 'https://i.ibb.co/KVLKK48/stabilizer.png'
    },
    ProductG: {
        title: {
            ger: 'Zentrierwerkzeug für die Wartung / Montage der Bremsscheiben ',
            eng: 'Rotor centering tool'
        },
        description: {
            ger: 'Das Werkzeug dient dem präzisen Zentrieren der Bremsscheiben bei der Montage. Zum Schutz der Oberflächen wurden die Zentrierbuchsen in Messing ausgeführt.',
            eng: 'The tool is used for precise centering of the brake discs during assembly. To protect the surfaces, the centering bushings were made of brass.'
        },
        dinType: {
            ger: 'DIN EN 1090-1 und -2 sowie DIN EN 3834-3',
            eng: 'DIN EN 1090-1 und -2 and DIN EN 3834-3'
        },
        developedFor: 'Falcon 7X',
        price: 'on request',
        imgPath:'https://i.ibb.co/7r4t4XM/centering-tool.png'
      }
  }

  export default productList;


