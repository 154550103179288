import styles from './Home.module.css';
import Titlepage from '../components/Titlepage/Titlepage.js';
import AboutAerobert from '../components/AboutAerobert/AboutAerobert.js';
import Products from '../components/Products/Products.js';
import AboutUs from '../components/AboutUs/AboutUs.js';
import productList from '../data/productList.js';
import { Section } from 'react-scroll-section';
import CookieConsent from "react-cookie-consent";




function Home( state ) {

    const language = state.lang;

    return (
    <div className="App">

        <Titlepage language = {language}></Titlepage>

        <CookieConsent>This website uses no cookies at the moment. It may be introduced in the future.</CookieConsent>

        <Section id="products">
            <Products language = {language} productList = {productList} />
        </Section>

        <Section id="aboutUs">
            <AboutUs language = {language}></AboutUs>
        </Section>

    </div>
    );
}

export default Home;