import React from 'react';
import styles from './Product.module.css';
import productText from '../../data/productsText.js';

const Product = props => {

    const developedAccordingTo = productText.developedAccordingTo[props.language];
    const developedFor = productText.developedFor[props.language];
    const price = productText.price[props.language];
    const shipmentConditions = productText.shipmentConditions[props.language];

    return(
        <div className={ styles.Product }>
            <div className={ styles.imgContainer }>
                <img src ={ props.productInfo.imgPath } alt ="not uploaded yet" className = { styles.img }/>
            </div>
            <div className={ styles.txtContainer }>

                <h1 className={ styles.title }>{ props.productInfo.title[props.language] }</h1>
                <p className={ styles.description }>{ props.productInfo.description[props.language] }</p>

                <div className={ styles.rowContainer }>
                    <p className={ styles.specs }> { developedAccordingTo } </p>
                    <p className={ styles.specs }>{ props.productInfo.dinType[props.language] }</p>
                </div>
                
                <div className={ styles.rowContainer }>
                    <p className={ styles.specs }> { developedFor } </p>
                    <p className={ styles.specs }>{ props.productInfo.developedFor }</p>
                </div>

                <div className={ styles.rowContainer }>
                    <p className={ styles.price }> { price } </p>
                    <p className={ styles.price }>{ props.productInfo.price }</p>
                </div>

                <div className={ styles.priceInformationContainer }>
                    <p className={ styles.priceInformation }> { shipmentConditions } </p>
                </div>

            </div>

        </div>
    );
}

export default Product;