import React from 'react';
import styles from './DrawerToggleButton.module.css';

const drawerToggleButton = props => (
    <button className={styles.button} onClick={props.click}>
        <div className={styles.line}/>
        <div className={styles.line}/>
        <div className={styles.line}/>
    </button>
);

export default drawerToggleButton;