import React from 'react';
import styles from './Impressum.module.css';

const Impressum = props => (
    <div className={ styles.Impressum }>
        <h3>Ginz GmbH</h3>
        <p>Geschäftsführer: Robert Ginz,  Florian Ginz,  Benjamin Ginz</p>

        <br />

        <p>Kreuzstr. 116</p>
        <p>79540 Lörrach</p>

        <br />

        <p>Tel: +49 (0) 7621 / 29 02</p>
        <p>Fax: +49 (0) 7621 / 29 18</p>
        <p>E-Mail: info@ginz-gmbh.com</p>

        <br />

        <p>www.ginz-gmbh.com</p>

        <br />

        <p>Umsatzsteuer-ID-Nummer:</p>
        <p>folgt (in Gründung)</p>

        <br />

        <p>Zuständige Handwerkskammer:</p>
        <p>Handwerkskammer Freiburg</p>
        <p>Handelsregister-Nr. folgt  (zur Zeit: in Gründung,  Urkundenrolle K UR 342 /2021)</p>

        <p>Gerichtsstand:</p>
        <p>Lörrach</p>
    </div>
);

export default Impressum;